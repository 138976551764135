<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-title -->
      <div class="header-column header-title">
        연수원 이용 신청
      </div>
      <!-- //header-title -->
      <!-- header-util -->
      <div class="header-column header-util right">
        <div class="util util-apply">
          <!--
          <a href="" class="util-actions util-actions-silver">
            다음
          </a>
          -->
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-facility">
    <!-- main-header -->
    <div class="main-header main-header-visual">
    </div>
    <!-- //main-header -->
    <!-- main-content -->
    <div class="main-content">

      <!-- content-section:연수원 -->
      <section class="content-section section-padding pt-5 mb-6">
        <header class="section-header header-divider">
          <h4 class="title">생활연수 예약 안내</h4>
        </header>
        <div class="section-contents">
          <p class="text font-body14">설렘과 웃음이 가득한 속초, 대천 연수원에 오셔서 즐겁고
            행복한 시간 보내시길 바랍니다.</p>
          <br>
          <div class="resv-top" v-html="trnctPopupNotice"></div>


<!--          <p class="text font-body14B mt-3">속초·대천연수원(안식년 휴가 활용 포함) 생활연수 안내</p>-->
<!--          <p class="text font-body14B mt-3">운영 예정일: 24.4.1(월) ~ 24.4.30(화)</p>-->
<!--          <p class="text font-body14B mt-1 text-gold">전산 Open : 24.3.4(월) 21:00 ~</p>-->

<!--          <br>-->
<!--          <p class="text font-body14B"><b>※ 참고</b> 23.10.27 HR부(인재개발) 579</p>-->
<!--          <p class="text font-body14B">속초·대천연수원 「생활연수 운영방법」 변경 안내</p>-->

          <!--
          <br>
          <p class="text font-body14B">* 속초연수원 5월 미운영일 안내</p>
          <p class="text font-body14B">5.7(일) ~ 5.11(목) 집합연수</p>
          <p class="text font-body14B">5.14(일) ~ 5.18(목) 집합연수</p>
          <p class="text font-body14B">5.21(일) ~ 5.25(목) 집합연수</p>
-->

        </div>
      </section>

      <!-- content-section: 이미지 슬라이더 -->
      <section class="content-section-wrap">
        <ImageSwiperMobileComponent :image-list="trainingArea"/>
      </section>

      <!-- content-section:연수원 -->
      <section class="content-section-wrap">
        <div class="section-divider">
          <div class="divider"></div>
        </div>
        <header class="section-header">
          <h4 class="title">연수원 선택</h4>
        </header>
        <div class="section-contents side-pd">
          <div class="kb-btn-actions">
            <button class="kb-btn-facility" :class="{ 'is-active' : venue == '1' }" @click="selectVenue('1')">속초 연수원</button>
            <button class="kb-btn-facility" :class="{ 'is-active' : venue == '2' }" @click="selectVenue('2')">대천 연수원</button>
            <!--            <button class="kb-btn-facility">강원 연수원</button>-->
          </div>
        </div>
      </section>

      <!-- content-section:방 -->
      <section class="content-section-wrap">
        <div class="section-divider">
          <div class="divider"></div>
        </div>
        <header class="section-header">
          <h4 class="title">방 유형 선택</h4>
        </header>
        <div class="section-contents side-pd">
          <div class="kb-btn-actions">
            <button v-if="venue == 2" class="kb-btn-facility" :class="{ 'is-active' : room == 'OR' }" @click="selectRoom('OR')">원룸</button>
            <button class="kb-btn-facility" :class="{ 'is-active' : room == 'TR' }" @click="selectRoom('TR')">투룸</button>
            <button class="kb-btn-facility" :class="{ 'is-active' : room == 'LTR' }" @click="selectRoom('LTR')">안식년(투룸)</button>
            <!--            <button class="kb-btn-facility">강원 연수원</button>-->
          </div>
        </div>
      </section>

      <!-- content-section:투숙기간 -->
      <section class="content-section-wrap">
        <div class="section-divider">
          <div class="divider"></div>
        </div>
        <header class="section-header">
          <h4 class="title">투숙기간 선택</h4>
        </header>
        <div class="section-contents side-pd">
          <div class="kb-btn-actions">
            <button class="kb-btn-facility" @click="openSelectPeriod">{{ getSelectPeriodTxt() }}</button>
          </div>
          <div class="join-btn">
            <button class="kb-btn kb-btn-primary" :disabled="!nextCheck" @click="nextStep">다음</button>
          </div>
        </div>
      </section>

    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>
<script>
import {computed, ref, watch} from 'vue';
import {useStore} from "vuex";
import { timestampToDateFormat} from "@/assets/js/util";

import ImageSwiperMobileComponent from '@/components/daily/mobile/ImageSwiperMobileComponent';
import {useRouter} from "vue-router";
import {ACT_SET_TRNCT_APLY_MOBILE_INFO} from "@/store/modules/trnct/trnct";
import {useAlert} from "@/assets/js/modules/common/alert";


export default {
  name: 'DailyMobileApplyStep0',
  components: {
    ImageSwiperMobileComponent
  },
  setup() {
    const store = useStore();
    // console.log("ROOM tracking", store.state.trnct.mobileApplyInfo.room)

    const router = useRouter();
    const {showMessage} = useAlert();

    const session = computed(() => store.state.auth.session);
    const nextCheckDef = computed(() => store.state.trnct.mobileApplyInfo.startDt === undefined ? false : true);
    const trnctPopupNotice = computed(()=>store.state.trnct.popupNoti.ctnt);
    const nextCheck = ref( nextCheckDef.value);
    const trainingArea = ref([
      {
        src: require('../../../../assets/lxp/images/daily/sockcho.jpg'),
        title: '속초연수원',
      },
      {
        src: require('../../../../assets/lxp/images/daily/sockcho.jpg'),
        title: '속초연수원',
      },
      {
        src: require('../../../../assets/lxp/images/daily/sockcho.jpg'),
        title: '속초연수원',
      }
    ]);

    const selectDate = ref(false);
    const reserveVenue = ref('연수원');
    const venue = ref(store.state.trnct.mobileApplyInfo.venue);

    const room = ref(store.state.trnct.mobileApplyInfo.room);
    // const room = ref(null);

    watch(() => venue.value, () => {
      room.value = null;
      nextCheck.value = null;

      store.commit(`trnct/${ACT_SET_TRNCT_APLY_MOBILE_INFO}`, {startDt: null, rsvtAplyDd: null});

      if(venue.value == 1){
        // room.value = 'TR';
        trainingArea.value = [{
          src: require('../../../../assets/lxp/images/daily/sockcho.jpg'),
          title: '속초연수원',
        }];

      }else{
        trainingArea.value = [{
          src: require('../../../../assets/lxp/images/daily/daecheon.jpg'),
          title: '대천연수원'
        }];
        // room.value = 'OR';
      }
    });

    const selectVenue = (num) => {
      venue.value = num;
      store.commit(`trnct/${ACT_SET_TRNCT_APLY_MOBILE_INFO}`, { venue: num });
    }

    const selectRoom = (type) => {
      room.value = type;
      store.commit(`trnct/${ACT_SET_TRNCT_APLY_MOBILE_INFO}`, { room: type, startDt: null, rsvtAplyDd: null});

    }

    const openSelectPeriod = () => {

      if(room.value == null){
        showMessage('먼저 방 유형을 선택 해 주세요');
      }else{
        router.push('/daily/apply/step0/date');
      }

    }

    const getSelectPeriodTxt = () => {
      if(store.state.trnct.mobileApplyInfo.startDt){
        return `입실 ${getDateFormat('yyyy.MM.dd', store.state.trnct.mobileApplyInfo.startDt.ymd)}`;
      }else{
        return '입실 날짜 선택';
      }
    }

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    const nextStep = () => {
      // const imsiList = [{id : '1505718', trnctMstSn : '1', 'date' : '2023-06-10'},
      //   {id : '3313257', trnctMstSn : '1', 'date' : '2023-09-12'},
      //   {id : '1642581', trnctMstSn : '1', 'date' : '2023-09-17'},
      //   {id : '2541018', trnctMstSn : '1', 'date' : '2024-09-19'},
      //   {id : '5907692', trnctMstSn : '1', 'date' : '2023-10-08'},
      //   {id : '5906897', trnctMstSn : '1', 'date' : '2024-10-16'},
      //   {id : '2518432', trnctMstSn : '1', 'date' : '2023-10-28'},
      //   {id : '5903708', trnctMstSn : '1', 'date' : '2024-11-06'},
      //   {id : '1550285', trnctMstSn : '1', 'date' : '2024-11-28'},
      //   {id : '3166541', trnctMstSn : '1', 'date' : '2024-12-05'},
      //   {id : '2523809', trnctMstSn : '1', 'date' : '2023-12-04'},
      //   {id : '3160702', trnctMstSn : '1', 'date' : '2023-12-01'},
      //   {id : '3166693', trnctMstSn : '2', 'date' : '2023-05-26'},
      //   {id : '1649041', trnctMstSn : '2', 'date' : '2023-05-27'},
      //   {id : '3172468', trnctMstSn : '2', 'date' : '2023-06-09'}]
      //
      // const imsi = imsiList.find(item => item.id === store.state.auth.session.lrnerId && item.trnctMstSn === venue.value);
      // if(imsi){
      //   if(new Date(imsi.date) > new Date()){
      //     showMessage('신청 불가');
      //     return;
      //   }
      // }

      if(!store.state.trnct.mobileApplyInfo.startDt){
        showMessage('입실 일자를 선택 해 주세요!');
      }else{
        store.commit(`trnct/${ACT_SET_TRNCT_APLY_MOBILE_INFO}`, { venue: venue.value, room: room.value, rsvtAplyDd: store.state.trnct.mobileApplyInfo.startDt.ymdformat, trnctMstSn: venue.value, trnctNm: venue.value == '1' ? '속초연수원' : '대천연수원', roomTy: room.value});
        console.log("###");
        console.log(store.state.trnct.mobileApplyInfo.venue);
        router.push('/daily/apply/step1');
      }
    }

    return {
      trainingArea,
      venue,
      room,
      reserveVenue,
      session,
      selectDate,
      selectVenue,
      selectRoom,
      openSelectPeriod,
      getSelectPeriodTxt,
      getDateFormat,
      nextCheck,
      nextStep,
      trnctPopupNotice
    };
  },
};
</script>
